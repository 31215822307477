import { Alert, Button, Col, Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import * as openpgp from "openpgp";

const ENDPOINT =
  "https://kufg8kiea8.execute-api.us-east-1.amazonaws.com/prod/secureform";

const publicFile = `-----BEGIN PGP PUBLIC KEY BLOCK-----
Version: Keybase OpenPGP v1.0.0
Comment: https://keybase.io/crypto

xsBNBGXWD2QBCACTfrMVohsKArYsv6qopVi6XY4TaT/mAGN5FnFuYVXl+fqbNeq1
Yv1/estfYEYjh2Ya5I3mXer4Ox5Bf6TejwFY9T31k/azcwGuCYrusJBT/FgHLc4W
/iHFK3WLfjnVSnr7puon75oXyWnri3bNNeN4kgrhzkXATK9CIsMAr4W76DVDc5Hf
M2pibIoVhse4PrVWvsWSEjIH/szbr+qwLxZ8M3dJEviNAzxQT3zSuHvEfuffyfb4
vBf8c4dzYcuPCdi7dDUSN85BHSN2pHmJAR1CCNY3/CHx2mp91IhhM9nv/Z+LGZkI
QFjHLTXkA4rhz9GTMgNerHK2UgTj4pG/PCYnABEBAAHNEkI5IDxkYW5pZWxhQGI5
LmNsPsLAbQQTAQoAFwUCZdYPZAIbLwMLCQcDFQoIAh4BAheAAAoJEArFFEz81iwU
OFwH/1OFNbz9zoGXwKmegngU3u8LTgH5jtGgMz/kIn8DwDwI8d9r/Gvr0z0nF+dX
/D/D97+QRqj6YNpAdrVdSDWxxgiyYVQa4HbQ1KBUfw81seilDuf5MQ7JjUpcjKft
E4nKGo5ThwzooysAEdrQvGGl5Ns6dD8HD1gZVpE4EnoOziPtIfJkPbYrMlTVotzb
Z2nuAH/QAWQtj1Gxc/l7xQDduUOqdUrcQ1q+cj9FCEez9IFj9IyXxmtv2rIMlgCx
7G9Lzrzl8OpiAyiodbEqBJ9c+1T/pK9h4WNl/0nlWIHR+SnjFmDWvEA09CGRhYiV
MQo7HwfbvEX3xAsrmunzzntM1vXOwE0EZdYPZAEIAON9NSXmrAOGNTwn99vJ+qIM
eO8fIjX1fzlB7XgoB2sP/WotEfqnjQzA7uf0CaXhpIEir6ux3VtA4kdgadpGUyQ4
z3pHUhqauokFkvDNz0p2Qq0qCIY9HakH7JA13tYwXv49XVAp2KsJGIGZnqYBaWKl
DsVRjcRZQdgqYDBzDBddYMolV8KY5ClnNVuhyycPnQx/UjQgZvrojaUlwLFhn0y9
pxvBFJXaRWYWDUD6JiOHc9JtxzAzwd8jLMeklRnxyr8HsnV/VEgvgXoXcmiWSFZ8
ELd7sFFHmvYCotCKnacIr8LrFR10ZjvLtbQ/RzW7gME81GItVhcuDN0rm5hZ7K8A
EQEAAcLBhAQYAQoADwUCZdYPZAUJDwmcAAIbLgEpCRAKxRRM/NYsFMBdIAQZAQoA
BgUCZdYPZAAKCRBKroUD8JZzsA8IB/9LGbTl+VpRIYNxVPg80vBaokKk/cKHjAuW
C+ASHY1hj1a2VCAQxNZeKyMX6jZQK/xJ+G+nyN+vBwymzIkQGMv4fuvN6vHz+nLr
DlOCqYg2WkLLT5Pr5JA+OXwoyQJ6/PQQS3CM6l4ClzyR1Ok6dasz490ze7Rmgw7i
naKd12xYt/FMPF58wZIrIOm7cnCt5O7vKsdMo8M2vGvNSnqIjBsHaq6jQJoYA2g4
9ue8RlJ5qRgVGLSN3YYu9Ck0LugEfVqPLnKnf5bSHEbqArHJxU0iBAXm7ZzBGVVr
DtXoGzsXdB03y0np4D1Trs9frBWvpdppwWjh8N8XDLotyKtuOw3HPlQH/RbwsFmx
2sXFVlvOiVDf4LpnYfw7xhZ96oTsFfYkBU8drHBq2R6UB4wXwKRGYINvsGJJT6K0
5T71yfYHRI9iq23AgTgGbh7mgHVFPmCN7wsyxTaEED3iNTpJsrBl1c8YWsE3XS/V
21ROE9sftzrIgnOpmgRG4tAi4Tw2Ypvg6ShGV2OB6ALxDsjPzbA6YEUzkRozqcuB
AhBu0WYmiizbGBHzhHOmaVzh/0LbddV5c2h3VttMvnexDaoFHZezTG8LQrci2Np7
dyCfs2fx/Aw/iaybG4hsKzHrcGPXOgHZeaJsV15vnFgEP7vGPMJlT2fwXMGpeZd+
4hmMZSHy3JMtn5nOwE0EZdYPZAEIALc0fo2UqhpDRGnMQt/a0NgocgalDyI3miL+
MidM9JaiPIhOX7WB9j8SYx3WUqrSOS3YiekDK3kK1cnhIum6atgWaau8j65nbqfi
RNq7nW3+4ZNI7A0dGF7wiqJQBRLEQqVq6pMyOUkFdobWgg7JpF7exbWRmY66BrQD
cAb2cYxWPTrTTzKqLFiFPCAR4SHgsqMIcJn+gWRSIONoMK1WnSeDjHk+Mlvgih10
y8OPUXXc28ERgWkRsPyqT/QHKyKiEkAbMOvcLm+Et2Nnfs0AIrCD983k37+wH0U2
fAzldZyCqpnO18cppiWQhQymM4sj7Y30eXGCqCNQBCAOU8oYhcsAEQEAAcLBhAQY
AQoADwUCZdYPZAUJDwmcAAIbLgEpCRAKxRRM/NYsFMBdIAQZAQoABgUCZdYPZAAK
CRBEfpqvfXzS+xtrCACwpBisfSsDo6dn/yDNZyXEHW2kiuSWxqGNgcWSH/K5s2dY
dTNNN2pj94sALXkG+Eiyp0vCoGwQvFHfZpEoYNhU9Be2cZ/11WTRZJBk2JGRKlU4
S839QxHxoIBG0juNRGDY87h9SdGihdps+EKQwN6sqwykKbFXi3VotZ/RbD7lXsHY
kxtR6YZ0W6FIOFpo55Xf4gdqxKp/jkIMsTuFNjcEJ8jLcfxDPXlbJffSBrxgJ4Fc
fNwi6eAWbh35hOy4mkjwdsQW7oxbwv9c/IyUUYNX8wN+pnlKdRMK9tBcNvz+7LfB
IX5pPIxrOdi5DTV7h5+aE1rGVpErZbwjZ4ztSAM4zQYH+weoyQVf8Wkgqs5EDNlE
qqc3u56D7ix03GC04GQkkPplgCAV2z5yE7oK/v23NoghEv0Kfnr0YlCNfzYzujVW
v5S+oB9wbiNHWsHPjgIASMLJz6EeoBqC7h2qRmELf812auxW7AHgQIukahWzX2zZ
ApQYFE5/CLwfyKUfZSyepuNOCEHQM+hT23LB5Y0yA7opX8FRBbwkCYki2CRWRe6S
bOk4bAHS6peXzs++up8/6e63EhYK44Jc6lJbEkov4hBmoac+MWkkigfi5lnYGeIa
2rOQnmsUD5z/dSA1P1CSdYEQ0Bssi/c/A8uK0PE4E/tMwuKTCYapIHiWLCKatDY2
a7w=
=lQfq
-----END PGP PUBLIC KEY BLOCK-----`;

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Debe ingresar un nombre."),
  email: Yup.string()
    .email("Email no válido")
    .required("Debe ingresar un email."),
  asunto: Yup.string().required("Debe ingresar asunto"),
  message: Yup.string().required("Debe ingresar un mensaje."),
});

type FormType = {
  name: string;
  email: string;
  asunto: string;
  message: string;
};

type AlertType = {
  show: boolean;
  title: string;
  message: string;
  variant: string;
};

const initialValues = {
  name: "",
  email: "",
  asunto: "",
  message: "",
};

const initialAlert: AlertType = {
  show: false,
  title: "Mensaje enviado!",
  message: "El mensaje fue enviado correctamente",
  variant: "success",
};

export default function SecureForm() {
  const [alert, setAlert] = useState(initialAlert);
  const [sending, setSending] = useState(false);

  const send = async (values: FormType, actions: any) => {
    setSending(true);

    const publicKey = await openpgp.readKey({ armoredKey: publicFile });

    const encrypted = await openpgp.encrypt({
      message: await openpgp.createMessage({ text: JSON.stringify(values) }),
      encryptionKeys: publicKey,
    });
    fetch(ENDPOINT, {
      method: "POST",
      body: JSON.stringify(encrypted),
      headers: {
        Accept: "application/json",
      },
    })
      .then((data) => {
        setAlert((prev) => {
          return { ...prev, show: true };
        });
        actions.resetForm();
      })
      .catch((e) => {
        setAlert({
          show: true,
          title: "Error!",
          message: "El mensaje no pudo ser enviado, inténtalo nuevamente",
          variant: "danger",
        });
      });
  };

  const close = () => {
    setAlert(initialAlert);
    setSending(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => send(values, actions)}
    >
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <Container fluid className="p-5 d-flex justify-content-center">
          <Col md={8} xs={12}>
            <ResponseAlert alert={alert} close={close} />
            <h1>Formulario de contacto para información privada</h1>
            <p>
              En B9 Ingeniería, entendemos la importancia de proteger la
              privacidad y confidencialidad de tu información. Por eso, hemos
              desarrollado un formulario en línea altamente seguro que utiliza
              cifrado avanzado para garantizar la protección total de tus datos.
            </p>
            <p>
              Si necesitas contactarnos debido a una emergencia entonces usa
              este <a href={"/emergencias"}>otro formulario</a>
            </p>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="fullName">
                <Form.Label className="d-flex">Nombre completo</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                />
                <Form.Control.Feedback type="invalid" className="d-flex">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label className="d-flex">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="example@mail.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                />
                <Form.Control.Feedback type="invalid" className="d-flex">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label className="d-flex">Asunto</Form.Label>
                <Form.Control
                  type="text"
                  name="asunto"
                  value={values.asunto}
                  onChange={handleChange}
                  isValid={touched.asunto && !errors.asunto}
                />
                <Form.Control.Feedback type="invalid" className="d-flex">
                  {errors.asunto}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="message">
                <Form.Label className="d-flex">Mensaje</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  isValid={touched.message && !errors.message}
                />
                <Form.Control.Feedback type="invalid" className="d-flex">
                  {errors.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" disabled={sending}>
                Enviar
              </Button>
            </Form>
          </Col>
        </Container>
      )}
    </Formik>
  );
}

function ResponseAlert({
  alert,
  close,
}: {
  alert: AlertType;
  close: () => void;
}) {
  return (
    <Alert show={alert.show} variant={alert.variant}>
      <Alert.Heading>{alert.title}</Alert.Heading>
      <p>{alert.message}</p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={close} variant={`outline-${alert.variant}`}>
          OK
        </Button>
      </div>
    </Alert>
  );
}
