import { Col, Container, Row } from "react-bootstrap";
import logo from "../logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import isbot from "isbot";
import { useEffect, useState } from "react";

export default function Inicio() {
  const [showContact, setShowContact] = useState<boolean>(false);
  const [contactUs, setContactUs] = useState<string>("");

  useEffect(() => {
    if (!isbot(navigator.userAgent)) {
      setShowContact(true);
      setContactUs(
        "Contáctanos hoy mismo y descubre cómo podemos ayudarte a concretar tus ideas."
      );
      console.info(
        "¡Hola, humano auténtico y no un bot malévolo! ¿Cómo podemos servirte en este día lleno de risas y alegría? Si deseas comunicarte con nosotros de manera más tradicional, puedes enviarnos un correo electrónico a contacto@b9.cl\n\n¡Estamos ansiosos por recibir tus mensajes!"
      );
    }
  }, []);

  return (
    <Container fluid className="p-5">
      <Row className="justify-content-center flex-wrap-reverse">
        <Col md={6} className="d-block align-self-center">
          <p className="text-start text-body">
            Somos un equipo compacto y especializado en el desarrollo de
            aplicaciones web, móviles y IoT. Nos dedicamos a convertir tus ideas
            en realidad, ofreciéndote soluciones personalizadas.
          </p>
          <p className="text-start text-body">
            En nuestro equipo, nos apasiona crear aplicaciones de calidad,
            utilizando tecnologías modernas y siguiendo las mejores prácticas de
            la industria.
          </p>
          <p className="text-start text-body">{contactUs}</p>

          {showContact && (
            <Container fluid>
              <Row>
                <Col xs={3}></Col>
                <Col xs={3}>
                  <p className={"fs-1"}>
                    <a href="mailto:contacto@b9.cl">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                  </p>
                </Col>
                <Col xs={3}>
                  <p className={"fs-1"}>
                    <a
                      href="https://wa.me/12185031337"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                  </p>
                </Col>
              </Row>
            </Container>
          )}
        </Col>
        <Col md={3} className="align-self-center">
          <img src={logo} alt="logo" className={"App-logo"} />
          <h6 className="text-black-50 pb-4 pt-3">
            Desarrollo de software para{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              aplicaciones web, móviles y IoT.
            </span>
          </h6>
        </Col>
      </Row>
    </Container>
  );
}
