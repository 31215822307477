import { Alert, Button, Col, Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

const ENDPOINT =
  "https://kufg8kiea8.execute-api.us-east-1.amazonaws.com/prod/emergency";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Debe ingresar un nombre."),
  phone: Yup.number()
    .typeError("Debe ingresar solo números")
    .integer("Debe ingresar solo números")
    .required("Debe ingresar un número de contacto.")
    .test("is-valid", "Número inválido", (value) =>
      /^((\+?56)?[2-9]{1})?\d{8}$/.test(String(value))
    ),
  message: Yup.string().required("Debe ingresar un mensaje."),
});

type FormType = {
  name: string;
  phone: string;
  message: string;
};

type AlertType = {
  show: boolean;
  title: string;
  message: string;
  variant: string;
};

const initialValues = {
  name: "",
  phone: "",
  message: "",
};

const initialAlert: AlertType = {
  show: false,
  title: "Mensaje enviado!",
  message:
    "El mensaje fue enviado correctamente, alguien de nuestro equipo te contactará a la brevedad",
  variant: "success",
};

export default function Emergency() {
  const [alert, setAlert] = useState(initialAlert);
  const [sending, setSending] = useState(false);
  const send = (values: FormType, actions: any) => {
    setSending(true);
    fetch(ENDPOINT, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        Accept: "application/json",
      },
    })
      .then((data) => {
        setAlert((prev) => {
          return { ...prev, show: true };
        });
        actions.resetForm();
      })
      .catch((e) => {
        setAlert({
          show: true,
          title: "Error!",
          message: "El mensaje no pudo ser enviado, inténtalo nuevamente",
          variant: "danger",
        });
      });
  };

  const close = () => {
    setAlert(initialAlert);
    setSending(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => send(values, actions)}
    >
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <Container fluid className="p-5 d-flex justify-content-center">
          <Col md={8} xs={12}>
            <ResponseAlert alert={alert} close={close} />
            <h1>EMERGENCIAS</h1>
            <p className="text-start text-body">
              Completa el siguiente formulario y un miembro de nuestro equipo te
              contactará a la brevedad.
            </p>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="fullName">
                <Form.Label className="d-flex">Nombre completo</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isValid={touched.name && !errors.name}
                />
                <Form.Control.Feedback type="invalid" className="d-flex">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label className="d-flex">Número telefónico</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Número para contacto rápido"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  isValid={touched.phone && !errors.phone}
                />
                <Form.Control.Feedback type="invalid" className="d-flex">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="message">
                <Form.Label className="d-flex">Mensaje</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Descripción de la emergencia."
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  isValid={touched.message && !errors.message}
                />
                <Form.Control.Feedback type="invalid" className="d-flex">
                  {errors.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit" disabled={sending}>
                Enviar
              </Button>
            </Form>
          </Col>
        </Container>
      )}
    </Formik>
  );
}

function ResponseAlert({
  alert,
  close,
}: {
  alert: AlertType;
  close: () => void;
}) {
  return (
    <Alert show={alert.show} variant={alert.variant}>
      <Alert.Heading>{alert.title}</Alert.Heading>
      <p>{alert.message}</p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={close} variant={`outline-${alert.variant}`}>
          OK
        </Button>
      </div>
    </Alert>
  );
}
